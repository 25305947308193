<template>
  <div>
    <div class="flex flex-col flex-1 h-full pb-8">
      <div v-if="!isDeploying" class="flex flex-col w-3/4">
        <div  class="flex">
          <div class="mb-8 mr-8 border border-blue-400 p-4 bg-blue-200">
            <div class="font-bold flex justify-between font-inter w-full">
              <span>Staging</span>
              <span class="rounded-full text-white bg-blue-500 p-2">
                  <icon name="checkmark" class="w-4 h-4"/>
              </span>
            </div>
            <p class="font-thin my-4 block text-sm">All changes will be deployed to the staging environment for
                you to preview.</p>
          </div>
          <div class="mb-8 border p-4 cursor-pointer"
              @click="deploy.production = !deploy.production"
              :class="{ 'border-gray-200 bg-gray-50' : !deploy.production, 'border-blue-400 p-4 bg-blue-200' : deploy.production }">
            <div class="font-bold flex justify-between font-inter w-full">
              <span>Production</span>
              
              <span class="rounded-full text-white bg-gray-300 p-2" 
                  :class="[deploy.production ? 'bg-blue-500' : 'bg-gray-300']" >
                  <icon :name="deploy.production ? 'checkmark' : 'close'" class="w-4 h-4"/>
              </span>
            </div>
            <p class="font-thin my-4 block text-sm">All changes pushed to this environment will be visible to
                the public on your website.</p>
          </div>
        </div>
        <div v-if="!hasHomePage" class="flex justify-between  border border-red-300 bg-warning-deploy px-4 py-3">
          <div class="flex justify-between">
            <icon name="alert" class="w-4 h-4 mr-2 icon-warning"></icon>
            <p class="font-inter text-white text-xs">
              <span >please choice a page as </span>
              <span class="font-bold">homepage </span>
              <span >to avoid issues with your website.</span>
            </p>
          </div>
        </div>
      </div>
      
      <div v-if="isDeploying" class="flex h-full w-full flex-1 items-center justify-center">
          <p class="text-lg font-frank">Deploying...</p>
      </div>
    </div>
    <modal-footer v-if="!isDeploying" :footer="footer" :primary="save"></modal-footer>
  </div>
</template>


<script>
import Icon from "@/components/ui/Icon";
import {mapActions, mapGetters} from "vuex";
import ModalNavigation from "@/mixins/ModalNavigation";
import ModalFooter from "@/components/ui/modals/ModalFooter";

export default {
    mixins: [ ModalNavigation ],
    components: { ModalFooter, Icon },
    data: () => {
        return {
            website: null,
            userEmail: null,
            deploy: {
                staging: true,
                production: false,
                forcePull: false,
            },
            isDeploying: false,
            footer: {
                primaryButton: 'Deploy'
            },
            hasHomePage: false,
        }
    },
    computed: {
        ...mapGetters({
            getUserEmail: 'auth/email',
            getWebsiteInfo: 'website_info/getWebsiteInfo'
        }),
    },
    methods: {
        ...mapActions({
            notifySuccess: 'alerts/notifySuccess',
            notifyError: 'alerts/notifyError',
        }),
        save() {
            if(this.isDeploying)
                return;

            this.isDeploying = true
            const payload = {
                isProduction: this.deploy.production,
                usersEmail: this.userEmail,
                forcePull: this.deploy.forcePull
            }

            this.$cmsDataProvider.create('createDeploy', { websiteId: this.website.id, data: payload }).then(() => {
                window.setTimeout(() => {
                    this.$router.push({name: 'deploys.index', params: {id: this.website.id}})
                    this.isDeploying = false
                    this.notifySuccess('The website was deployment was submitted.')
                }, 3000)

            }).catch(() => {
                this.notifyError('There was an error trying to deploy the website.')
                this.isDeploying = false
            })
        },
        fetchWebsiteHomePage() {
          return this.$cmsDataProvider.get('websiteHasHomePage', {websiteId: this.website.id}).then((response) => {
              if(response.status == 'ok')
                this.hasHomePage = true;
          })
        },       
    },
    async mounted() {
        this.website = this.getWebsiteInfo
        this.userEmail = this.getUserEmail;
        this.deploy.forcePull = this.$route.params.forcePull ?? false
        this.fetchWebsiteHomePage();
    },
}
</script>

<style scoped>
  .bg-warning-deploy{
    background: #c77266;
  }

  .icon-warning{
    mix-blend-mode: color-dodge !important;
    filter: invert(100%) !important;
  }
</style>